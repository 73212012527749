<template>
    <div class="container content">
        <div class="headline">
            <h1>{{ $t("message.register-user.header") }}</h1>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">
                        {{ $t("message.register-user.instructions.header") }}
                    </p>
                    <div
                        v-html="$t('message.register-user.instructions.txt')"
                    ></div>
                </article>
            </div>
            <div class="tile is-parent is-8">
                <article class="tile is-child box">
                    <p class="title">
                        {{ $t("message.register-user.add.header") }}
                    </p>
                    <div class="columns">
                        <div class="column is-half">
                            <b-field
                                :label="
                                    $t('message.register-user.add.firstname')
                                "
                            >
                                <b-input v-model="newUser.first_name"></b-input>
                            </b-field>

                            <b-field
                                :label="
                                    $t('message.register-user.add.lastname')
                                "
                            >
                                <b-input v-model="newUser.last_name"></b-input>
                            </b-field>

                            <b-field
                                :label="$t('message.register-user.add.email')"
                                :type="emailFieldType"
                            >
                                <b-input
                                    type="email"
                                    value=""
                                    v-model="newUser.email"
                                    @blur="validateEmail"
                                >
                                </b-input>
                            </b-field>
                            <small v-if="validator.message.email != ''" class="errormessage">{{ validator.message.email }}</small>
                        </div>
                        <div class="column is-half">
                            <b-field
                                :label="$t('message.register-user.add.phone')"  :type="mobileFieldType"
                            >
                                <b-input @blur="validateMobile" v-model="newUser.mobile"></b-input>

                            </b-field>
                            <small v-if="validator.message.mobile != ''" class="errormessage">{{ validator.message.mobile }}</small>
                            <b-field
                                :label="
                                    $t('message.register-user.add.password')
                                "
                            >
                                <b-input
                                    @blur="validateFirstPassword"
                                    type="password"
                                    value=""
                                    v-model="newUser.password"
                                    password-reveal
                                >
                                </b-input>
                            </b-field>
                            <password
                                @input="validateFirstPassword"
                                v-model="newUser.password"
                                :strength-meter-only="true"
                            />
                            <b-field
                                :label="
                                    $t(
                                        'message.register-user.add.confirm-password'
                                    )
                                "
                                                                :type="passwordFieldType"
                                :message="passwordFieldMessage"
                            >
                                <b-input
                                    @blur="validatePassword"
                                    type="password"
                                    value=""
                                    v-model="newUser.c_password"
                                    password-reveal
                                >
                                </b-input>
                            </b-field>
                        </div>
                    </div>

                    <b-button
                        :disabled="isDisabled"
                        :loading="false"
                        :label="$t('message.register-user.add.send')"
                        size="is-large"
                        class="fullwidth"
                        type="is-success"
                        v-on:click="register"
                    />
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import Password from "vue-password-strength-meter";
import axios from 'axios';

export default {
    components: {
        Password,
    },
    data() {
        return {
            user: false,
            newUser: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                password: "",
                c_password: "",
                lang: "fi",
                type: "enduser",
                invite: false
            },
            errors: {
                is_error: false,
                first_name: false,
                last_name: false,
                email: false,
                mobile: false,
                password: false,
                c_password: false,
            },
            validator: {
                password: true,
                vat: true,
                email: true,
                mobile: true,
                message: {
                    email: "",
                    mobile: "",
                    password: "",
                    vat: ""
                }
            },
        };
    },
    methods: {
        validateInvite(inviteHash) {
            axios.get('/validate-invite/'+inviteHash).then((response) => {
                if(response.data.success) {
                    this.newUser.first_name = response.data.employee.first_name;
                    this.newUser.last_name = response.data.employee.last_name;
                    this.newUser.email = response.data.employee.email;
                    this.newUser.mobile = response.data.employee.mobile;
                    this.newUser.password = "";
                    this.newUser.c_password = "";
                    this.newUser.lang = response.data.employee.lang;
                    this.newUser.invite = inviteHash;
                }
                else {
                    this.toastFailure(
                        this.$t(response.data.message)
                    );
                }
            });
        },
        validateEmail() {
            axios.post('users/validate-email',{ email: this.newUser.email}).then((response) => {
                if(response.data.success) {
                    this.validator.email = true;
                    this.validator.message.email = "";
                } else {
                    this.validator.email = false;
                    this.validator.message.email = this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateMobile() {
            axios.post('users/validate-mobile',{ mobile: this.newUser.mobile}).then((response) => {
                if(response.data.success) {
                    this.validator.mobile = true;
                    this.validator.message.mobile = "";
                } else {
                    this.validator.mobile = false;
                    this.validator.message.mobile =  this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateFirstPassword() {
            if(this.newUser.c_password != "") {
                this.validatePassword();
            }
        },
        validatePassword() {
           if(this.newUser.password == this.newUser.c_password && this.newUser.password.length > 5) {    
                this.validator.password = true;
                this.validator.message.password = "";
            } 
            else {
                this.validator.password = false;
                this.validator.message.password = this.$t("message.error.password-mismatch");
                if(this.newUser.password.length < 5) {
                    this.validator.message.password = this.$t("message.error.password-too-short");
                }
            }
        },
        register() {
            if (this.newUser.password != this.newUser.c_password) {
                this.toastFailure(
                    this.$t("message.partner.toaster.user.password-mismatch")
                );
                return;
            }
            this.$store
                .dispatch("createUser", this.newUser)
                .then((response) => {
                    var status = response.status;
                    if (status >= 200 && status < 300) {
                        this.$router.push("/rekisteroi-kayttaja-kiitos");
                    } else {
                        this.toastFailure(
                            this.$t(
                                "message.register-user.toaster.creationError"
                            )
                        );
                    }
                })
                .catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(
                        this.$t("message.register-user.toaster.creationError")
                    );
                });
        },
    },
    mounted() {
        var hash = this.$route.params.hash;
        if (hash && hash != undefined && hash != null) {
            this.validateInvite(hash);
        }
    },
    computed: {
        isDisabled() {
            if(this.newUser.password.length < 6) {
                return "disabled";
            }
            if(!this.validator.password || !this.validator.password) {
                return "disabled";
            }
            if(!this.validator.email) {
                return "disabled";
            } 
            if(!this.validator.mobile) {
                return "disabled";
            }
            return false; 
        },
        passwordFieldType() {
            if(this.validator.password) {
                return "";
            }
            return "is-danger"
        },
        passwordFieldMessage() {
            if(this.validator.message.password != "") {
                return this.validator.message.password
            }
            return "";
        },
        emailFieldType() {
            if(this.validator.email) {
                return "";
            }
            return "is-danger"
        },
        emailFieldMessage() {
            if(this.validator.message.email != "") {
                return this.validator.message.email
            }
            return "";
        },
        mobileFieldType() {
            if(this.validator.mobile) {
                return "";
            }
            return "is-danger"
        },
        mobileFieldMessage() {
            if(this.validator.message.mobile != "") {
                return this.validator.message.mobile
            }
            return "";
        },
    }
};
</script>
<style>
.errormessage {
    display: block;
    color: red;
    width: 100%;
    font-size: 0.75rem;
    margin-top: -0.75rem;
}
.help.is-danger {
    color: red !important;
}
</style>